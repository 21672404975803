
  import t0 from "./theme-client";
  import t2 from "./theme.asset-2.js";
import t1 from "./theme.asset-1.js";


  export const themes = {  2: t2,
 1: t1,
 }

  export const getThemeById = (id) => id && themes[id] ? themes[id] : t0

  export default t0;
