export default {
  API_URL: "https://api-cbreuk-prod.findyourspot.io",
    API_SERVICE_URL: "https://services.bookacorner.io",
  CLOUDFRONT_URL: "https://files.findyourspot.io",
  CLIENT: "cbreuk",
  CLIENT_NAME: "CBREUK",
  GOOGLE_MAPS_API_KEY: "AIzaSyDuLujNf0BlXLrQYVCUFrnQ2aA9y0AnmVM",
  HOME_URL: "https://uk.findyourspot.io",
  UPLOAD_URL: "https://saxtyc5ci8.execute-api.us-east-1.amazonaws.com/prod",
  LOGGLY_KEY: "b523b1f3-6dd4-4be4-a10f-017f039f4de5",
  PROFORMA_URL: "https://bac-mediafiles.s3.amazonaws.com/all/proforma-cbre.html",
  HOME_HEADER_IMG_URL: "https://files.findyourspot.io/all/media/Fotos+de+portada+para+HOME/Foto+Centro+1.jpg",
  HOME_COMMENT_TEXT: "LAS ACCIONES TEMPORALES DE BRANDING EN CBRE SON TODO UN ÉXITO, YA QUE SUS LOCALES Y SU PÚBLICO SON ÚNICOS EN ESPAÑA",
  HOME_COMMENT_AUTHOR: "Nike Director",
  HOME_COMMENT_LINK: "https://www.cbre.es/en/services/industries-and-specialties/retail",
  HOME_COMMENT_IMAGE: "https://www.cbre.es/-/media/cbre/countryspain/images/oficinas/oficina_barcelona.png",
  LOGO_LIGHT: "https://files.findyourspot.io/all/media/CBRE/cbre-logo-light.png",
  LOGO_DARK: "https://files.findyourspot.io/all/media/CBRE/cbre-logo-dark.png",
  ADMIN_DEFAULT_LOCALE: "en-cbreuk",
  TENANT_DEFAULT_LOCALE: "en-cbreuk",
  ABOUT_US: "",
  HELP: "",
  TERMS_AND_CONDITIONS: "https://bookacorner.com/condiciones-generales",
  PRIVACY_POLICY: "https://files.bookacorner.net/all/media/Privacy Policy/CBRE Privacy Policy.pdf",
  SOCIAL_LINKS: {"facebook":"https://www.facebook.com/cbreuk","twitter":"https://twitter.com/CBRE_UK?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor","instagram":"https://www.instagram.com/cbre_uk/?hl=es"},
  LEVEL_NAMES: ["-1", "0", "1", "2", "3", "4"],
  ADVERTISING_IMG: "https://files.findyourspot.io/all/media/912de3e4-b8bc-4a9e-bbf4-f3c0d5ffb6ef.jpg",
  REVERB_APP_KEY: "p4ZFuOnpih5yI9Vdtv2N0I2l",
  REVERB_HOST: "api-cbreuk-prod.findyourspot.io",
  REVERB_AUTH_ENDPOINT: "https://api-cbreuk-prod.findyourspot.io/api/broadcasting/auth",
  REVERB_PORT: 6001,
  FONT_API_URL: "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap",
  INDEXABLE: "true",
  LOCALES_SUPPORTED_ARRAY: ["es","en","fr","pt","es-cbreuk","en-cbreuk","pt-cbreuk","fr-cbreuk"],
  MAPBOX_API_KEY: "pk.eyJ1IjoibWF4aW1pbGlhbm9iYWMiLCJhIjoiY2wxd2U5YXFrMGNzajNjbzE3MmZrNXljeiJ9.s4gzv9rbU479joxwhHVJkA",
  HOME_MAP_STYLE: "mapbox://styles/maximilianobac/cl2ozroc6000f14p5hn0c4zvp",
  HOME_MAP_BOUNDS: [[-8.684724574732039,49.31652629269804],[2.1542445913160457,61.166149317192016]],
  MAPBOX_MARKER: "https://files.findyourspot.io/all/markers/mapbox-icon-CBRE.png",
    PDF_WORKER: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.12.313/build/pdf.worker.min.js",
  USER_MANUAL: "",
  COGNITO_URL: "",
}
